.u-marginAuto {
    margin: auto !important;
}

.u-paddingAn {
    padding: 0px !important;
}

.u-paddingAs {
    padding: 5px !important;
}

.u-paddingAm {
    padding: 10px !important;
}

.u-paddingAml {
    padding: 15px !important;
}

.u-paddingAl {
    padding: 20px !important;
}

.u-paddingAl-24 {
    padding: 24px !important;
}

.u-paddingxl {
    padding: 40px !important;
}

.u-marginAn {
    margin: 0px !important;
}

.u-marginAs {
    margin: 5px !important;
}

.u-marginAm {
    margin: 10px !important;
}

.u-marginAml {
    margin: 15px !important;
}

.u-marginAl {
    margin: 20px !important;
}

.u-marginAl-24 {
    margin: 24px !important;
}

/*vertical*/

.u-paddingVn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.u-paddingVs {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.u-paddingVm-8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.u-paddingVm {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.u-paddingVl {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.u-paddingVxl {
    padding-top: 28px !important;
    padding-bottom: 28px !important;
}

.u-marginVn {
    margin-top: 0  !important;
    margin-bottom: 0  !important;
}

.u-marginVs {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.u-marginVm {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.u-marginVm-12 {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.u-marginVl {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

/*horizontal*/

.u-marginHn {
    margin-left: 0  !important;
    margin-right: 0  !important;
}

.u-marginHs {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.u-marginHm {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.u-marginHm-12 {
    margin-left: 12px !important;
    margin-right: 12px !important;
}

.u-marginHl {
    margin-left: 20px !important;
    margin-right: 20px !important;
}


.u-paddingHn {
    padding-left: 0  !important;
    padding-right: 0  !important;
}

.u-paddingHs {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.u-paddingHm {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.u-paddingHm-12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.u-paddingHl {
    padding-left: 20px !important;
    padding-right: 20px !important;
}


/*bottom*/

.u-paddingBn {
    padding-bottom: 0 !important;
}

.u-paddingBs {
    padding-bottom: 5px !important;
}

.u-paddingBm-8 {
    padding-bottom: 8px !important;
}

.u-paddingBm {
    padding-bottom: 10px !important;
}

.u-paddingBl {
    padding-bottom: 20px !important;
}

.u-paddingBxl {
    padding-bottom: 28px !important;
}

.u-marginBn {    
    margin-bottom: 0  !important;
}

.u-marginBs {    
    margin-bottom: 5px !important;
}

.u-marginBm {    
    margin-bottom: 10px !important;
}

.u-marginBm-12 {    
    margin-bottom: 12px !important;
}

.u-marginBl {    
    margin-bottom: 20px !important;
}

.u-marginBxl {    
    margin-bottom: 24px !important;
}

/*Left*/

.u-marginLn {
    margin-left: 0  !important;    
}

.u-marginLs {
    margin-left: 5px !important;    
}

.u-marginLm {
    margin-left: 10px !important;    
}

.u-marginLm-12 {
    margin-left: 12px !important;    
}

.u-marginLl {
    margin-left: 20px !important;
}

/*Right*/

.u-marginRn {
    margin-right: 0  !important;    
}

.u-marginRs {
    margin-right: 5px !important;    
}

.u-marginRm {
    margin-right: 10px !important;
}

.u-marginRm-12 {
    margin-right: 12px !important;
}

.u-marginRl {
    margin-right: 20px !important;
}