.nested-card {
  width: 100%;
}

.sub-card-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(194, 194, 189);
  gap: 30px;
  padding: 10px;
  width: 90%;
}
.sub-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
}

.sub-card-value {
  font-size: 24px;
  color: rgba(13, 69, 133, 0.938);
}

.subtitle-alpha {
  color: rgb(148, 150, 151);
}

@media only screen and (max-width: 600px) {
  .sub-card-main-container {
    width: 90%;
  }

  .sub-card-value {
    font-size: 24px;
    color: rgba(13, 69, 133, 0.938);
  }
}
