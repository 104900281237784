//Card 1

.card-items {
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  filter: drop-shadow(4px 16px 20px #00000021);
}

//Card 2
.sectionTwoCardTwo {
  margin: 20px;
  box-shadow: 2px 15px 63px -17px rgba(0, 0, 0, 0.404);
  background-color: rgb(177, 177, 175);
}

.cardTwo-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cardTwo-header {
  display: flex;
  justify-content: space-between;
  span {
    color: rgb(132, 134, 136);
  }
}

.cardTwo-header-value {  
  font-size: 40px;  
}

.cardTwo-graph {
  height: 300px;
}
.cardTwo-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
}

.ref-color-1 {
  background-color: rgb(241, 9, 9);
  width: 20px;
  content: "";
  min-height: 100%;
}
.ref-color-2 {
  background-color: rgb(29, 197, 51);
  width: 20px;
  content: "";
  min-height: 100%;
}
.ref-color-3 {
  background-color: rgb(11, 53, 167);
  width: 20px;
  content: "";
  min-height: 100%;
}

.composed-graph-card {
  max-width: 1054px;
  min-height: 480px;  
}

@media screen and (max-width:1800px) and (min-width: 600px) {
  .sectionTwoMain {
    .wallet-movements {
      display: none !important;
    }  
    .composed-graph-card {
      max-width: 76vw;    
    }
  } 

}


@media screen and (max-width: 600px) {
  .sectionTwoMain {
    .composed-graph-card {
      display: none !important;
    }
    .wallet-movements {
      width: 95%;
    }
  }  
}

