.need-help-font-styles {
  font-family: Open Sans;
  text-align: left;
  letter-spacing: -0.44px;
  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 21.79px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 29.96px;
  }
}

.contact-card {
  width: 100%;
  max-height: 280px;
}

.u-custom-config-card-1, .u-custom-config-card-2 {
  width: 90%;
}

.u-custom-config-card-text-2 {
  padding-bottom: 25px;
}
.contact-card-section {
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .config-mobile-display {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

  .u-customMobileWidth {
    max-width: 100% !important;
  }

  .contact-card-section {
    flex-direction: column;
  }
}

@media screen and (max-width: 1500px) and (min-width: 600px) {
  .contact-card-section {
    flex-direction: row;
    .base-data-card {
      width: 100%;
    }
  }
}
