.u-displayInlineBlock {
    display: inline-block !important;
}

.u-displayBlock {
    display: block !important;
}

.u-displayFlex {
    display: flex !important;
}

.u-displayInlineFlex {
    display: inline-flex !important;
}

.u-hidden {
    display: none !important;
}

.u-displayGrid {
    display: grid !important;
}

.u-visible {
    visibility: visible !important;
}

.u-visibilityHidden {
    visibility: hidden !important;
}