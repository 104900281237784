/*backgrounds*/
.u-background-dark-blue {
  background-color: var(--color-dark-blue);
}

.u-background-medium-blue {
  background-color: var(--color-medium-blue);
}

.u-background-bright-blue {
  background-color: var(--color-bright-blue);
}

.u-background-light-blue {
  background-color: var(--color-light-blue);
}

.u-background-lightest-blue {
  background-color: var(--color-lightest-blue);
}

.u-password-card-background {
  background-color: #9cd3eb80;
}

.u-pass-input-background {
  background-color: #e3f5ff;
}

.u-white-background {
  background-color: #ffffff !important;
}

/*colors*/
.u-color-dark-blue {
  color: var(--color-dark-blue);
}

.u-color-medium-blue {
  color: var(--color-medium-blue);
}

.u-color-bright-blue {
  color: var(--color-bright-blue);
}

.u-color-light-blue {
  color: var(--color-light-blue);
}

.u-color-lightest-blue {
  color: var(--color-lightest-blue);
}

.u-color-white {
  color: #ffffff;
}

.u-color-success {
  color: #4aa785;
}

.u-color-danger {
  color: #F14D4D;
}

.u-color-title-card {
  color: #a3aed0 !important;
}

.u-color-title-card-secondary {
  color: #006BB6 !important;
}

/*Placeholder Colors*/
.u-placeholder-dark-blue::placeholder {
  color: var(--color-dark-blue);
}

.u-placeholder-medium-blue::placeholder {
  color: var(--color-medium-blue);
}

.u-placeholder-bright-blue::placeholder {
  color: var(--color-bright-blue);
}

.u-placeholder-light-blue::placeholder {
  color: var(--color-light-blue);
}

.u-placeholder-lightest-blue::placeholder {
  color: var(--color-lightest-blue);
}

.u-transparent-white-background {
  background-color: rgba(255, 255, 255, 0.05);
}

.u-color-lighter-blue {
  color: #85d1ef;
}

.u-fog-white-background {
  background-color: #e9eef3;
}

.color-grayed-subtitle {
  size: 14px;
  font-weight: 400;
  font-family: "Inter";
  color: #00000066;
}

.u-password-card {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 680px;
  height: 656px;
  border-radius: 24px;
}

.u-main-font-family {
  font-family: "Gotham Rounded", sans-serif;
}

.u-secondary-font-family {
  font-family: Open Sans !important;
}

/*Work in progress: adaptar fuentes del figma */

.u-titles {
  font-size: 2em;
}

.u-subtitles {
  width: 311px;
  height: 73px;
  top: 185px;
  left: 58px;
  padding: 0px 26px 0px 0px;
}

.u-paragraph {
  width: 162px;
  height: 48px;
  top: 289px;
  left: 58px;
  padding: 0px 18px 2px 0px;
  gap: 0px;
  opacity: 0px;
}

.u-linkStyleless {
  text-decoration: none !important;
}

.sign-in-button {
  background-color: #e3f5ff;
  color: #042e5d;
  position: absolute;
  top: 2%;
  right: 3%;
  border: none;
  border-radius: 5px;
  padding: 0.2em 0.5em;
  cursor: pointer;
}

/*----------------------Dashboard General Styles---------------------------*/

.card-shadow {
  box-shadow: 0px 18px 40px 0px #7090B01F;
}

.base-data-card {
  border-radius: 20px;
  padding: 1em;
}

.card-graph-title {  
  font-size: 14px;
  font-weight: 350;
  line-height: 16.8px;
  margin-left: 35px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: left;
  color: #006BB6;
  display: inline-block;
}

.default-data-card-bg {
  background-color: #f7f9fb;
  color: #2b3674;
}

.seccondary-data-card-bg {
  background: linear-gradient(to bottom right, #042e5d, #85d1ef);
  color: #ffffff;
  background-image: url("../assets/login-vector-background.svg");
  background-size: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.seccondary-data-card-side-bg {
  background: linear-gradient(to right, #85d1ef, #214584, #002e61),
    url("../assets/bubble-image.png");
  background-size: cover;
  background-blend-mode: multiply;
  color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.blue-gradient-bg {
  background: linear-gradient(to left, #85D1EF, #042E5D);
  color: #fafafa;
}

.card-label {
  font-family: "Gotham Rounded", sans-serif !important;
  font-size: 14px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
}

.card-price {
  font-family: "Gotham Rounded", sans-serif;
  font-size: 20px;
  font-weight: 350;
  line-height: 32px;
  letter-spacing: -0.02em;
  text-align: left;
}

.card-sub-label {
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;
}

.card-icon-container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(to bottom right, #042e5d, #85d1ef);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.seccondary-card-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #042e5d;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/*------------------------------------------------*/
/* Corregir a colores del figma */

.tertiary-data-card-side-bg {
  background: linear-gradient(to bottom left, rgb(50, 117, 193), #edeeef),
    url("../assets/login-vector-background.svg");
  color: #ffffff;
  background-size: cover;
  background-blend-mode: hard-light;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.tertiary-data-card-side-no-bg {
  background: linear-gradient(127deg, #042E5D, #81CCEB, #EFEFEF);
  color: #ffffff;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
