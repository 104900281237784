.main-dashboard {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);
}

.dashboard-header {
  max-width: 1440px;
  height: 160px;
  align-content: center
}
.main-dashboard-content {
  max-width: 1440px;
}

.mobile-wrap {
  min-height: 100vh;
  margin-bottom: 100px;
}

@media screen and (max-width: 600px) {
  .dashboard-header {
    display: none;
  }
  .u-marginLl {
    margin: auto !important;
  }
  .main-chart-container {
    tspan {
      font-size: 10px;
    }
  }
}
