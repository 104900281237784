.header-container {
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 20px;
  max-width: 1930px;
  margin-top: 45px;
}

.header-bar {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 20px;
  gap: 10px;
  border-radius: 40px;
  height: 60px;
  width: 420px;
  margin-right: 30px;
}

.languageSelector {
  justify-content: center;
  align-items: center;
}
