.main-gestion {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);
}

.gestion-wrap {
  min-height: 100vh;
  margin-bottom: 200px;
  padding: 20px 20px;
}
.u-flexCenter {
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .gestion-header {
    display: none;
  }
}
