.u-justifyCenter {
  justify-content: center;
  align-items: center;
}

.u-justifyEnd {
  align-items: end;
  justify-content: flex-end;
  margin-left: auto;
}

.u-justifyStart {
  align-items: center;
  justify-content: start;
  text-align: center;
}

.card-width {
  width: 100% !important;
}

.config-form-container {
  width: 100%;
  height: 260px;
}

.gridColumns {
  display: grid;
  grid-template-columns: auto auto;
  gap: 30px;
}

.company-card {
  width: 100%;
  height: 100%;
}

.u-config-logo-border {
  border: 1px dashed #929da9a3;
  border-radius: 10px;
  width: 40%;
}

.u-badge {
  background-color: rgb(0, 0, 0, 0.05);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15%;
  color: black;
  font-size: 12px;
}

.need-help-font-styles {
  font-family: Open Sans;
  text-align: left;
  letter-spacing: -0.44px;
  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 21.79px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 29.96px;
  }
}

@media screen and (max-width: 600px) {
  .mobile-display {
    display: flex;
    flex-direction: column;
  }
  .u-config-logo-border {
    width: 100%;
  }
  .config-form-container {
    width: 100%;
  }
  .u-mobileMargin {
    margin: 10px !important;
  }
}

@media screen and (max-width: 1500px) {
  .config-structure-page {
    .form-container {
      width: 100%;
    }
  }
}
