.main-portfolio {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);
}

.portfolio-header {
  max-width: 1440px;
  height: 160px;
  align-content: center
}

.wrap {
  min-height: 100vh;
}

.main-portfolio-content {
  max-width: 1440px;
}

@media screen and (max-width: 600px) {
  .portfolio-header {
    display: none;
  }

  .porfolio-mobile-display {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
