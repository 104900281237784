.bottomBarFixed {
  background: linear-gradient(to right, #85d1ef, #214584, #002e61);
  position: -webkit-fixed;
  position: fixed;
  width: 100%;
  bottom: 0;
  margin-top: auto;
  height: 80px;
  padding: 20px 0px;
}

.bottomBar-container {
  justify-content: space-around;
}

.text-white {
  color: white;
}

.round-borders {
  border-radius: 100%;
}

.nav-link {
  transition: all 1s ease;
  padding: 10px 0px;
  text-decoration: none;
  position: relative;
  top: 10px;
  font-size: 14px;
  p {
    font-family: "Inter";
    visibility: hidden !important;
  }

  i {
    color: rgb(166, 214, 249);
    transition: all 2s ease;
  }
}

.nav-link.active {
  transition: all 2s ease;
  text-decoration: none;
  p {
    font-family: "Inter";
    bottom: 40px;
    position: relative;
    visibility: visible !important;
  }

  i {
    bottom: 20px;
    position: relative;
    color: white;
    transition: all 2s ease;
  }
}

@media screen and (min-width: 600px) {
  .mobileOnly {
    visibility: hidden !important;
    display: none !important;
  }
  p {
    display: none;
  }
}
