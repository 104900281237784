
@media screen and (max-width: 600px) {    
    header {
        position: relative !important;
        width: 100% !important;
        height: 95px !important;
        
        button {
            width: 60px;
            height: 60px;
        }
    }
    .mobile-header {
        
        flex-direction: row !important;        
        justify-content: space-between;
        align-items: center;
        height: 60px !important;
    }

    .page-title {
        font-family: 'Inter';
        text-transform: capitalize;  
        justify-content: space-between;        
        height: 60px;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
        text-align: center;
        color: #343C6A;
        align-items: center;
    }
}

@media screen and (max-width: 1400px) and (min-width: 600px) {
     nav li span {
       display: none !important;
    }
    
    header {
       display: none !important;
    }
}

.drawer-container {
    text-align: center;
}