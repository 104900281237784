.cash-card {
  width: 314px;
  height: 188px;
}

// .main-chart-container {
//     //padding: 1em;
// }

.mobile-display {
  display: none;
}
.only-mobile {
  display: none !important;
}
.areaLine-graph-card {
  max-height: 426px;
  max-width: 1066px;
  width: 100%;
  h3 {
    width: 250px;
  }
  span {
    width: 160px;
  }
}

.lowres-cash-card {
  display: none !important;
}

.dashboard-section-one {
  
  .top-cards-container { 
    .lowres-second-line {
      flex-grow: 1;
    }
  }

  .actual-wallet {
    min-width: 250px;
  }

  .initial-value,
  .first-year-value,
  .ytd-balance {
    min-width: 280px;
  }

  .wallet-cards {
    .card-label {
      font-size: 16px !important;
    }
  }

  .mobile-datacheck {
    display: none !important;
  }
}

@media screen and (max-width: 1800px) and (min-width: 600px) {
  .dashboard-section-one {
    .only-mobile {
      display: none !important;
    }
    .top-cards-container {
      flex-direction: column;
    }
    .card-icon-container {
      display: none;
    }
    .card-price {
      font-size: 20px;
    }
    .card-label {
      font-size: 12px;
    }
    .non-lower-res-data {
      display: none;
    }
    .areaLine-card-labels {
      font-size: 10px;
    }
    .cash-card {
      display: none;
    }
    .lowres-cash-card {
      display: block !important;
      justify-items: center;
    }

    .lowres-first-line,
    .lowres-second-line {
      max-width: 77vw;
      width: 100%;
      flex-wrap: nowrap !important;
      justify-content: space-between;
      align-items: center;
    }

    .lowres-first-line .base-data-card,
    .lowres-second-line .base-data-card {
      flex: 100%;
      max-width: calc(100% / 4);
      text-align: center;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .lowres-second-line .base-data-card {
      flex: 100%;
      max-width: calc(100% / 3);
    }

    .lowres-first-line .base-data-card,
    .lowres-second-line .base-data-card {
      height: 100px;
    }

    .areaLine-graph-card {
      max-width: 76vw;
    }
    .actual-wallet {
      min-width: 0;
    }

    .initial-value,
    .first-year-value,
    .ytd-balance {
      min-width: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .non-mobile-data {
    display: none !important;
  }
  .only-mobile {
    display: flex !important;
  }
  .mobile-card {
    flex-direction: column !important;
    width: 47%;
    padding: 10px !important;
  }
  .mobile-card-seccondary {
    align-items: center;
  }
  .cash-card {
    height: 104px !important;
    width: 97% !important;
  }
  .dashboard-second-line {
    flex-direction: column-reverse;
  }
  .cash-card-direction {
    flex-direction: row !important;
  }
  .card-label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .card-price {
    font-size: 14px;
    font-weight: 350;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .card-icon-container {
    width: 30px;
    height: 30px;
    font-size: 10px;
    margin-bottom: 5px;
  }

  .card-sub-label {
    font-size: 8px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .u-marginAm {
    margin: 5px !important;
  }

  .wallet-cards {    
    width: 100%;    
  }

  .areaLine-graph-card {
    max-width: 95vw;
    h3 {
      width: 100%;
      font-size: 12px;
    }
    span {
      font-size: 10px;
    }
    button {
      min-width: 110px;
    }
  }
  .dashboard-section-one {
    .card-icon-container {
      display: none;
    }
    .mobile-display {
      display: flex;
    }
    .mobile-datacheck {
      display: flex !important;
      .value {
        width: 75px;
      }
      svg {
        width: 10px;
      }
    }
    .top-cards-container {
      .lowres-first-line {
        flex-wrap: wrap;
      }
    }

    .actual-wallet {
      min-width: 0;
    }

    .initial-value,
    .first-year-value,
    .ytd-balance {
      min-width: 0;
    }
  }
}
