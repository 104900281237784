.u-justifyStart {
  align-items: center;
  justify-content: start;
  text-align: center;
}

.u-justifyCenter {
  justify-content: center;
  align-items: center;
}
.u-alignItemStart {
  align-items: start;
}

hr.solid {
  border-top: 1px solid #cfcccc;
  width: 100%;
  padding: 0px;
  color: #cfcccc;
}

.gap-utility {
  gap: 50px;
}

.icon-border {
  border: 1px #3333 solid;
  border-radius: 100%;
  padding: 10px 15px;
}

.u-marginBottom50 {
  margin-bottom: 50px;
}
.custom-button {
  background: linear-gradient(
    232deg,
    rgba(0, 46, 97, 1) 10%,
    rgba(33, 69, 132, 1) 62%,
    rgba(133, 209, 239, 1) 100%
  );
  padding: 15px 20px;
  display: flex;
  border-radius: 50px;
  align-items: center;
  color: white;
  gap: 20px;
}

.need-help-font-styles {
  font-family: "Inter";
  text-align: left;
  letter-spacing: -0.44px;
  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 21.79px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 29.96px;
  }
}

.subtitle {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 29.96px;
  color: rgba(43, 54, 116) !important;
  display: flex;
  justify-content: flex-start;
  width: 40%;
}
