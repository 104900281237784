.cash-card-porfolio {  
  width: 235px;
  height: 111px;
}


.displayNone {
  display: none;
}

@media screen and (max-width: 600px) {
  .mobile-display {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}
.participants-evolution-barchart, .actives-diversification-donutchart  {
  width: 100%;
  //max-width: 500px;
}

.right-row {
  flex-direction: column;
}

.nav-portfolio-card {
  width: 100%;
}

.portfolio-lowres-firstline, 
.portfolio-lowres-secondline {
  flex-grow: 1;
  .base-data-card {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1800px)  and (min-width: 600px) {   
  
  .right-row {
    display: flex;
    flex-direction: row !important;
    .base-data-card {
      width: 33%;
    }
  }

  .portfolio-firstline {
    flex-direction: column ;
    .card-icon-container {
      display: none;
    }

    .portfolio-lowres-firstline, 
    .portfolio-lowres-secondline {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .base-data-card {
        flex: 100%; 
        max-width: calc(100% / 2);
        height: 100px;
      }
    }

  }
  .nav-portfolio-card {  
    max-width: 54vw;
  }

  .main-portfolio .portfolio-middle-section {
    flex-direction: column-reverse;
  } 
  
}

@media screen and (max-width: 600px) {
  .main-portfolio .chart-bottomline {
    flex-direction: column;
    .actives-diversification-donutchart, .participants-evolution-barchart {
      width: 97%;
      max-width: 97%;
      height: 250px;
    }
    
    
    .subSectionTwo-container {
      flex-direction: column;

      .cash-card-porfolio {
        width: 97%;
      }
    }    
  }
  .nav-portfolio-card {
    max-width: 95vw;
    align-self: center;
  }
  .portfolio-lowres-firstline, .portfolio-lowres-secondline {
    justify-content: center;
  }

  .portfolio-firstline {
    flex-direction: column;
    .base-data-card {
      width: 45%;
      .card-icon-container {
        display: none;
      }
    }    
  }
  .main-portfolio .subSectionTwo-container {
    flex-direction: column;
    .right-row {
      justify-self: center;
    }
    .cash-card-porfolio, .portfolio-per-ytd {
      width: 95vw;
    }
  }
}