.u-Width100 {
    width: 100% !important;
}

.u-Width95 {
    width: 95% !important;
}

.u-Width90 {
    width: 90% !important;
}

.u-Width70 {
    width: 70% !important;
}

.u-Width50 {
    width: 50% !important;
}

.u-Width25 {
    width: 25% !important;
}

.u-Height100 {
    height: 100% !important;
}

.u-Height50 {
    height: 50% !important;
}

.u-Height25 {
    height: 50% !important;
}