.profile_avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  padding: 5px;
}
.profile_avatar_bg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.u-justifyCenter {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  text-align: center;
}

.card-items {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(4px 16px 20px #00000021);
}

.user-plan-section-two {
  .right-side-card {
    max-width: 391px;
  }
}
@media screen and (max-width: 1600px) and (min-width: 600px) {
  .user-plan-section-two {
    .right-side-card {
      max-width: 310px;
    }
  }
}