.gap20 {
  gap: 20px;
}
.displayNone {
  display: none;
}

u.justifyCenter {
  justify-content: center;
  align-items: center;
}

.portfolios-list {
  align-items: center;
  display: flex;
  margin-left: 5px!important;
  margin-bottom: 15px;
  .card-icon-container {
    margin-right: 10px;
  }
}

.portfolio-wallet-mov {
  min-width: 312px;
}

.portfolio-lowres-contact {
  display: none;
}

@media screen and (max-width: 1800px)  and (min-width: 600px) {   
  .portfolio-lowres-contact {
    display: block;
  } 
}

@media screen and (max-width: 600px) {
  .mobile-column {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
  .portfolio-wallet-mov {
    width: 95%;
    justify-self: center;
    margin-top: 15px !important;
  }
}
