.tabs-container {
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .mobileDisplay {
    visibility: hidden !important;
  }
}
