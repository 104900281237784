.main-benchmark {
    //height: 100vh;
    width: 100%;
    background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);

    .card-price {
        margin-left: 35px;
    }

    .benchmark-tag {
        padding-right: 24px;        
        padding-left: 24px;
        border-radius: 10px;
        box-shadow: 10px 24px 54px 0px #17204105;
        h2 {            
            font-weight: 350;
            font-size: 14px;
            line-height: 20px;            
        }
    }

    

    @media screen and (max-width: 600px) {
        .benchmark-card-label {
            font-size: 8px;
        }
        .benchmark-tag {
            margin-top: 10px;
        }
        .card-graph-title {            
            margin-left: 5px;
            line-height: 1;
        }
      }
}