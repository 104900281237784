.SearchBar {
  padding: 20px;
}

.search {
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.searchBar-Input {
  width: 100%;
  height: 25px;
  border: none;
  font-size: 15px;  
}

.searchBar-Input::placeholder {
  color: "#A3AED0";  
  opacity: 1;
}
