.progress-bg{
    width: 100%;
    height: 0.25rem;
    background-color: #fbfbfb;
    border-radius: 0 0 0.2rem 0.2rem;
    position: relative;
}

.progress{
    width: 0%;
    height: 0.25rem;
    position: absolute;
    border-radius: 0 0 0.2rem 0.2rem;
    transition:  all 0.5s ease-in-out;
}

.meter-text {
    font-size: 13px;
    font-weight: 400px;
    color: #F7F9FB;
}