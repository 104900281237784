.need-help-font-styles {
  font-family: Open Sans;
  text-align: left;
  letter-spacing: -0.44px;
  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 21.79px;
  }
  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 29.96px;
  }
}

.card-items {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(4px 16px 20px #00000021);
}

.diversification-card,
.distribution-card {
  max-height: 280px;
  height: 100%;
  min-width: 500px;
}

.dashboard-third-block {
  .lowres-display {
    display: none !important;
  }
}

.contact-card-HQ {
  width: 24%;
  height: 280px;
}

@media screen and (max-width: 1800px) and (min-width: 600px) {
  .dashboard-third-block {
    .lowres-display {
      display: block !important;
      width: 33vw;
    }
    .contact-card {
      width: 96%;
    }

    .donut-cards {
      flex-direction: column;
    }
    .contact-card-HQ {
      display: none;
    }
    .distribution-card,
    .diversification-card {
      max-height: none;
      height: 100%;
      max-width: 43vw;
    }
  }
}

@media screen and (max-width: 600px) {
  .donut-cards {
    flex-direction: column;
    height: 500px;
    max-width: 95vw;
  }

  .contact-card-HQ {
    width: 95%;
    height: 300px;
  }

  .diversification-card,
  .distribution-card {
    min-width: 300px;
  }
}
