.languageMenuIcon {
  position: relative;
}

.dropdown {
  display: flex;
  position: absolute;
  top: 4em;
  right: -0.5em;
  border-radius: 10px;
  border: 1px solid rgba(151, 151, 153, 0.87);
  background-color: white;
  box-shadow: 2px 15px 63px -17px rgba(0, 0, 0, 0.233);
  cursor: pointer;
  width: 150px;
  z-index: 10;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    right: 1.5em;
    position: relative;
    list-style: none;
    width: 100%;
  }
  .lang1:hover {
    text-decoration: underline black;
  }

  .lang2:hover {
    text-decoration: underline black;
  }
  .lang3:hover {
    text-decoration: underline black;
  }
}

.dropdown::before {
  content: "";
  position: absolute;
  top: -0.7em;
  right: 1em;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background-color: white;
  border-left: 1px solid rgba(151, 151, 153, 0.87);
  border-top: 1px solid rgba(151, 151, 153, 0.87);
  box-shadow: 2px 15px 63px -17px rgba(0, 0, 0, 0.233);
}

.lngicon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.lngIconSmall {
  width: 24px;
  width: 24px;
}

.dropDownOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
