.u-absoluteCenter {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.u-textAlignCenter {
    text-align: center !important;
}

.u-centerBlock {
    display: block !important;
    margin: auto;
}

.u-flexColumn {
    display: flex !important;
    flex-direction: column !important;
}

.u-flexRow {
    display: flex !important;
    flex-direction: row !important;
}

.u-justifySpaceBetween {
    justify-content: space-between !important;
}

.u-justifyCenter {
    justify-content: center !important;
}

.u-flexWrap {
    flex-wrap: wrap;
}

.u-alignSelfEnd {
    align-self: self-end;
}

.u-alignItems {
    align-items: center !important;
}

.u-alignContentCenter {
    align-content: center !important;
}

.u-justifyItemsCenter {
    justify-items: center !important;
}