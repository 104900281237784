.input-pass {    
    padding: 12px 10px;
    border: none;
    border-radius: 12px;

    &:focus {
        outline: none;
      }
}

.input-pass-wrapper {

  position: relative;
  display: inline-block;
}



.hidePassword-btn {
  position: absolute;
  right: 5%;
  top: 55%;
  transform: translateY(-50%);
  cursor: pointer;
}