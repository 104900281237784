.primary-view {
    width: 100%;
    height: 100%;
    grid-template-columns: 20% 80%;
}

@media screen and (max-width: 1400px) and (min-width: 600px) {
    .primary-view {
        grid-template-columns: 10% 90%;
    }
}

@media screen and (max-width: 600px) {
    .primary-view {
        grid-template-columns: 1fr;
    }
}
