.main-usuarios {
  .u-justifyEvenly {
    justify-content: space-evenly;
  }

  .user-wallet-avalue {
    min-width: 225px;
  }
  
  .u-justifyCenter {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .gap4 {
    gap: 10px;
  }
  .gap5 {
    gap: 30px;
  }
  
  .areaLine-graph-card {
    max-width: 870px;
    margin-right: 20px;
    h3 {
      width: 170px;
    }
  }

  @media screen and (max-width: 1400px) and (min-width: 600px) {
    .diversification-donutchart {      
      max-width: 300px!important;
    }
    .areaLine-graph-card {
      max-width: 56vw;
    }
    .base-data-card {
      .card-price {
        font-size: 16 !important;
      }
    }
  }
  
  @media screen and (max-width: 600px) {
    .mobileDisplay {
      display: none !important;
    }
    .visibleDisplay {
      display: block !important;
    }
  }
  
  .contributions-barchart {  
    width: 100%;
    max-width: 470px;
  }
  
  .diversification-donutchart {
    width: 100%;
    max-width: 465px;
  }
  
  .historic-custom-lines-chart {
    max-width: 946px;
  }
}
