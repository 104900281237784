.dropzone {
  border: 2px #b7b3b3 dashed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 100%;
}

.drag-drop-form {
  width: 100%;
  max-width: 100%;
}
.drag-drop-text {
  font-family: Open Sans;
  letter-spacing: -0.44px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 15px;
}

.upload-button {
  margin-top: 50px;
  background-color: transparent; /* Green */
  border: 2px #b8b4b4 solid;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.need-help-font-styles {
  font-family: Open Sans;
  letter-spacing: -0.44px;
}

.font-inter {
  font-family: "Inter";
}

//Acepted Files
.uploaded-files {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  background-color: rgba(238, 241, 247, 1);
}
.gap30 {
  gap: 100px;
}

.uploaded-files-preview {
  display: flex;
  gap: 20px;
}

//Rejected Files

.rejected-files-card {
  background-color: #dba0a0;
  display: flex;
  justify-content: center;
}

.rejected-files-card-msg {
  font-family: Open Sans;
  letter-spacing: -0.44px;
  align-items: center;
  display: flex;
  text-align: center;
  gap: 30px;
}
