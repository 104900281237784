.main-config {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);
}

.config-wrap {
  min-height: 100vh;
  margin-bottom: 200px;
}

.config-structure-page {
  flex-direction: row;
}

@media screen and (max-width: 600px) {
  .config-header {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .config-structure-page {
    flex-direction: column;
  }
}
