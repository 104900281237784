.forgot-component {
    background: linear-gradient(to bottom right, #042E5D, #85D1EF);
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }


form {
    width: 100%;
    max-width: 360px;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    margin: auto;
  }

h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  font-family: 'Gotham Rounded', sans-serif;
}

span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.logo-pass-flow {
  width: 90%;
  max-width: 350px;
  position: absolute;
  top: 2%;
  left: 3%;  
}

.login-footer {
  position: absolute;
  bottom: 1%;
  width: 80%;
  flex-wrap: wrap;
  //flex-direction: space-between;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  list-style: none !important;
  flex-direction: row !important;
  li {
    padding-right: 1em;
  }  
}