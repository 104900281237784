.main-usuarios {
  height: 100%;
  width: 100%;
  background: radial-gradient(circle, #86b7c3 0%, #fafafa 100%);
}

.wrap {
  min-height: 100vh;
}

.usuarios-header {
  height: 160px;
}

.cuil-search {
  max-height: 60px;
  max-width: 950px;
  margin-left: 15px;
  margin-right: 15px;
  justify-self: center;
  width: 100%;
  text-align: center;

  input {
    width: 80%;
    border: none;
    margin-left: 15px;
    background-color: #f7f9fb;
  }
}

@media screen and (max-width: 600px) {
  .usuarios-header {
    display: none;
  }
  .display_column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .web-display {
    display: none !important;
  }
  .cuil-search {
    margin-top: auto;
  }
}

@media screen and (min-width: 600px) {
  .mobile-display {
    display: none !important;
  }
}
